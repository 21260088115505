define("ember-print-this/templates/components/print-this", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NWNXHX7n",
    "block": "[[[18,1,[[28,[37,1],[[30,0],\"doPrint\"],null]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"action\"]]",
    "moduleName": "ember-print-this/templates/components/print-this.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});