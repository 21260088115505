define("ember-engines/-private/engine-ext", ["@embroider/macros/es-compat", "@embroider/macros/runtime", "@ember/application", "@ember/engine", "ember-engines/components/link-to-external"], function (_esCompat, _runtime, _application, _engine, _linkToExternal) {
  "use strict";

  _engine.default.reopen({
    buildRegistry() {
      let registry = this._super(...arguments);
      if (!(this instanceof _application.default)) {
        if ((0, _runtime.macroCondition)(!true)) {
          const EngineScopedLinkComponent = (0, _esCompat.default)(require("ember-engines/components/link-to-component")).default;
          registry.register('component:link-to', EngineScopedLinkComponent);
        }
        registry.register('component:link-to-external', _linkToExternal.default);
      }
      return registry;
    }
  });
});