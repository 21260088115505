define("ember-print-this/components/print-this", ["exports", "@ember/service", "@ember/component", "ember-print-this/templates/components/print-this"], function (_exports, _service, _component, _printThis) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    layout: _printThis.default,
    printThis: (0, _service.inject)(),
    defaultPrintClass: 'content__printThis',
    classNameBindings: ['defaultPrintClass'],
    printSelector: null,
    autoPrint: false,
    options: null,
    actions: {
      doPrint() {
        this._print();
      }
    },
    didInsertElement() {
      if (this.get('autoPrint')) {
        this._print();
      }
    },
    _print() {
      const printSelector = this.get('printSelector') || '';
      this.get('printThis').print(printSelector, this.get('options') || {});
    }
  });
  _exports.default = _default;
});