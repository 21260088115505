define("ember-print-this/services/print-this", ["exports", "@ember/polyfills", "@ember/application", "jquery", "@ember/service"], function (_exports, _polyfills, _application, _jquery, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _service.default.extend({
    print(printSelector, options) {
      let jQuery = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _jquery.default;
      const environment = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      const mergedOptions = this._constructPrintOptions(environment, options);
      this._selectElement(printSelector, jQuery).printThis(mergedOptions);
    },
    _constructPrintOptions() {
      let environment = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        rootURL: '/'
      };
      let userOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const base = environment.rootURL || environment.baseURL;
      const options = base === '/' ? {} : {
        base
      };
      return (0, _polyfills.assign)(options, userOptions);
    },
    _selectElement: function (toSelect, jQuery) {
      return jQuery(toSelect);
    }
  });
  _exports.default = _default;
});