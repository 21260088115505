define("ember-cli-notifications/templates/components/notification-message", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)( /*
                                                                {{! template-lint-disable no-invalid-interactive no-triple-curlies }}
                                                              <div
                                                                class="c-notification
                                                                  {{this.dismissClass}}
                                                                  {{this.clickableClass}}
                                                                  {{if this.validType (concat "c-notification--" this.validType) ""}}
                                                                  {{@notification.cssClasses}}"
                                                                data-test-notification-message={{@notification.type}}
                                                                {{on "mouseenter" this.handleMouseEnter}}
                                                                {{on "mouseleave" this.handleMouseLeave}}
                                                              >
                                                                <div class="c-notification__icon">
                                                                  {{#if this.validType}}
                                                                    {{#if this.isInfo}}
                                                                      <EcnIconInfo class="c-notification__svg" />
                                                                    {{else if this.isSuccess}}
                                                                      <EcnIconSuccess class="c-notification__svg" />
                                                                    {{else if this.isWarning}}
                                                                      <EcnIconWarning class="c-notification__svg" />
                                                                    {{else if this.isError}}
                                                                      <EcnIconError class="c-notification__svg" />
                                                                    {{/if}}
                                                                  {{/if}}
                                                                </div>
                                                                <div class="c-notification__content" {{on "click" this.handleOnClick}}>
                                                                  {{#if @notification.htmlContent}}
                                                                    {{{@notification.message}}}
                                                                  {{else}}
                                                                    {{@notification.message}}
                                                                  {{/if}}
                                                                  <div
                                                                    class="c-notification__close"
                                                                    {{on "click" this.removeNotification}}
                                                                    title="Dismiss this notification"
                                                                  >
                                                                    <EcnIconClose class="c-notification__svg" />
                                                                  </div>
                                                                </div>
                                                              
                                                                {{#if @notification.autoClear}}
                                                                  <div
                                                                    class="c-notification__countdown"
                                                                    style={{this.notificationClearDuration}}
                                                                  ></div>
                                                                {{/if}}
                                                              </div>
                                                              */{
    "id": "NjJ3vnvd",
    "block": "[[[11,0],[16,0,[29,[\"c-notification\\n    \",[30,0,[\"dismissClass\"]],\"\\n    \",[30,0,[\"clickableClass\"]],\"\\n    \",[52,[30,0,[\"validType\"]],[28,[37,1],[\"c-notification--\",[30,0,[\"validType\"]]],null],\"\"],\"\\n    \",[30,1,[\"cssClasses\"]]]]],[16,\"data-test-notification-message\",[30,1,[\"type\"]]],[4,[38,2],[\"mouseenter\",[30,0,[\"handleMouseEnter\"]]],null],[4,[38,2],[\"mouseleave\",[30,0,[\"handleMouseLeave\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"c-notification__icon\"],[12],[1,\"\\n\"],[41,[30,0,[\"validType\"]],[[[41,[30,0,[\"isInfo\"]],[[[1,\"        \"],[8,[39,3],[[24,0,\"c-notification__svg\"]],null,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isSuccess\"]],[[[1,\"        \"],[8,[39,4],[[24,0,\"c-notification__svg\"]],null,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isWarning\"]],[[[1,\"        \"],[8,[39,5],[[24,0,\"c-notification__svg\"]],null,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isError\"]],[[[1,\"        \"],[8,[39,6],[[24,0,\"c-notification__svg\"]],null,null],[1,\"\\n      \"]],[]],null]],[]]]],[]]]],[]]]],[]],null],[1,\"  \"],[13],[1,\"\\n  \"],[11,0],[24,0,\"c-notification__content\"],[4,[38,2],[\"click\",[30,0,[\"handleOnClick\"]]],null],[12],[1,\"\\n\"],[41,[30,1,[\"htmlContent\"]],[[[1,\"      \"],[2,[30,1,[\"message\"]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,1,[\"message\"]]],[1,\"\\n\"]],[]]],[1,\"    \"],[11,0],[24,0,\"c-notification__close\"],[24,\"title\",\"Dismiss this notification\"],[4,[38,2],[\"click\",[30,0,[\"removeNotification\"]]],null],[12],[1,\"\\n      \"],[8,[39,7],[[24,0,\"c-notification__svg\"]],null,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,1,[\"autoClear\"]],[[[1,\"    \"],[10,0],[14,0,\"c-notification__countdown\"],[15,5,[30,0,[\"notificationClearDuration\"]]],[12],[13],[1,\"\\n\"]],[]],null],[13]],[\"@notification\"],false,[\"if\",\"concat\",\"on\",\"ecn-icon-info\",\"ecn-icon-success\",\"ecn-icon-warning\",\"ecn-icon-error\",\"ecn-icon-close\"]]",
    "moduleName": "ember-cli-notifications/templates/components/notification-message.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});