define("ember-cli-notifications/templates/components/ecn-icon-info", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)( /*
                                                                <svg ...attributes aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill="currentColor" d="M7.75 0a7.751 7.751 0 000 15.5 7.75 7.75 0 100-15.5zm0 3.438a1.312 1.312 0 110 2.624 1.312 1.312 0 010-2.625zm1.248 7.937a.375.375 0 01-.375.375H6.928a.375.375 0 01-.375-.375v-.75-3.254c0-.207.168-.375.375-.375h1.695c.207 0 .375.168.375.375v4.004z"></path></svg>
                                                              */{
    "id": "8WLLG/xY",
    "block": "[[[11,\"svg\"],[17,1],[24,\"aria-hidden\",\"true\"],[24,\"focusable\",\"false\"],[24,\"role\",\"img\"],[24,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[24,\"viewBox\",\"0 0 16 16\"],[24,\"width\",\"16\"],[24,\"height\",\"16\"],[12],[10,\"path\"],[14,\"fill\",\"currentColor\"],[14,\"d\",\"M7.75 0a7.751 7.751 0 000 15.5 7.75 7.75 0 100-15.5zm0 3.438a1.312 1.312 0 110 2.624 1.312 1.312 0 010-2.625zm1.248 7.937a.375.375 0 01-.375.375H6.928a.375.375 0 01-.375-.375v-.75-3.254c0-.207.168-.375.375-.375h1.695c.207 0 .375.168.375.375v4.004z\"],[12],[13],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "ember-cli-notifications/templates/components/ecn-icon-info.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});