window.is_turkmen = getCookie('IS_TURKMEN') === 'true';
const protocol = window.location.protocol;
window.is_3g = window.location.host.indexOf('3g') === 0;
window.GLOBAL_API = window.is_turkmen ? {
  configs: `${protocol}//configs.adrenalineslot.com`,
  files: `${protocol}//files-v1.adrenalineslot.com`,
  trans: `${protocol}//trans.adrenalineslot.com`,
  media: `${protocol}//media-avif.adrenalineslot.com`,
  map_attacks: `${protocol}//map-attacks.adrenalineslot.com`,
  chat_iframe: `${protocol}//live-chat.adrenalineslot.com`,
} : {
  configs: `${protocol}//configs.bigstarbet.com`,
  files: `${protocol}//files.bigstarbet.com`,
  trans: `${protocol}//trans.bigstarbet.com`,
  media: `${protocol}//media-avif.bigstarbet.com`,
  map_attacks: `${protocol}//map-attacks.bigstarbet.com`,
  chat_iframe: `${protocol}//live-chat.bigstarbet.com`,
}

if(window.location.href.includes('https://www.')) {
  window.location.replace(window.location.href.replace('https://www.', 'https://'));
}

if(window.location.href.includes('authToken=') && localStorage.getItem('ember_simple_auth-session') !== '{"authenticated":{}}') {
  localStorage.setItem('ember_simple_auth-session', '{"authenticated":{}}');
  location.reload();
}

let loadPartnerConfigPromise = null;

checkPartner()

function domain_from_url(origin) {
  var result
  var match
  if (match = origin.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im)) {
    result = match[1]
    if (match = result.match(/^[^\.]+\.(.+\..+)$/)) {
      result = match[1]
    }
  }
  return result
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function checkPartner() {
  const is_preview = window.location.search.indexOf('is_preview=1') !== -1;
  window.is_preview = is_preview

  let url = `${window.GLOBAL_API.configs}/${is_preview ? 'preview.' : ''}${window.location.host}.js`;

  if (window.has_seo) {
    loadPartnerConfigPromise = Promise.resolve();
    if(window.partnerConfigs.domain_manager?.redirect_url) {
      window.location.replace(window.partnerConfigs.domain_manager?.redirect_url + window.location.search);
    }
    checkPartnerMirror();
    sendRequestIpinfo();

  } else {
    loadPartnerConfigPromise = jQuery.ajax({
      type: "GET",
      url: url,
      success: () => {
        if(window.partnerConfigs.domain_manager?.redirect_url) {
          window.location.replace(window.partnerConfigs.domain_manager?.redirect_url + window.location.pathname + window.location.search);
        }
        checkPartnerMirror();
        setCodeInjections();
        setStyles();
        sendRequestIpinfo();
        // setPWA();
        setSeo();
      },
      dataType: "script",
      cache: true
    });
  }
}

function sendRequestIpinfo() {
  if (!window.partnerConfigs.is_turkmen) {
    $.get("https://siteapi-v3.bigstarbet.com/get-location", function (response) {
      console.log(response);
      window.ip = response.ip;
      window.country = response.alpha2;
    });
  } else {
    window.country_code = 'TM';
    window.country = 'TM';
  }
}

function sendRequestIpinfo_old() {
  if (!window.partnerConfigs.is_turkmen) {
    $.get("https://ipinfo.io", function (response) {
      window.ip = response.ip;
      window.country = response.country;
    }, "jsonp");
  } else {
    window.country_code = 'TM';
    window.country = 'TM';
  }
}

function checkPartnerMirror() {
  let mirrors = this.partnerConfigs.mirrors;
  if (mirrors && mirrors.length) {
    const countryCode = window.country ? window.country : window.country_code
    let mirror = mirrors.find(k => k.country_code === countryCode);
    if (mirror) {
      window.location.replace(mirror.redirect_url);
    }
  }
}

function setCodeInjections() {
  let code_injection = window.partnerConfigs.code_injection.find(v => v.type === (window.isMobile ? 'mobile' : 'desktop'));
  if (code_injection) {
    $('head').prepend($(code_injection.before_head))
    $('head').append($(code_injection.after_head))
    $('body').prepend($(code_injection.before_body))
    $('body').append($(code_injection.after_body))
  }
}

function setSeo() {
  window.partnerConfigs.seo = window.partnerConfigs.seos.find(v => v.path === '/*' && v.language_id === 'en');
  if (window.partnerConfigs.seo) {
    $('head').append(`<meta name="description" content="${this.partnerConfigs.seo.description}">`);
    $('meta[name=keywords]').attr('content', this.partnerConfigs.seo.keywords);
    document.title = this.partnerConfigs.seo.title;
  }
}

function setPWA() {
  if (this.partnerConfigs.configs.pwa_active) {

    let url = this.partnerConfigs.configs.pwa_manifest_url.replace('https://betproduction-file-managers.s3.eu-central-1.amazonaws.com', window.GLOBAL_API.media)
    $('head').append(`<link rel="manifest" href="${url}">`);
  }
}

function setStyles() {
  let theme = window.localStorage.themeMode;
  console.log('theme', theme);

  if (theme) {
    document.documentElement.setAttribute("data-theme", theme);
  } else {
    document.documentElement.setAttribute("data-theme", 'dark');
  }

  let partnerConfigs = window.partnerConfigs;

  if (partnerConfigs) {
    let partnerStyles = partnerConfigs.partner_styles;

    if (partnerStyles) {
      let styles_dark = JSON.parse(partnerStyles.styles_dark);
      let styles_light = JSON.parse(partnerStyles.styles_light);

      let element = document.querySelector('html');
      setStyles();

      var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.type == "attributes" && mutation.attributeName === 'data-theme') {
            setStyles();
          }
        });
      });

      observer.observe(element, {
        attributes: true
      });

      function setStyles() {
        switch (element.dataset.theme) {
          case 'dark':
            if (partnerStyles.loading_dark) {
              $('.loading-wrapper .loading span').css('background-image', `url(${partnerStyles.loading_dark.replace('https://betproduction-file-managers.s3.eu-central-1.amazonaws.com', window.GLOBAL_API.media)})`);
            }

            styles_dark.forEach(item => {
              element.style.setProperty(item.key, item.color)
            })
            break;
          case 'light':
            if (partnerStyles.loading_dark) {
              $('.loading-wrapper .loading span').css('background-image', `url(${partnerStyles.loading_light.replace('https://betproduction-file-managers.s3.eu-central-1.amazonaws.com', window.GLOBAL_API.media)})`);
            }

            styles_light.forEach(item => {
              document.documentElement.style.setProperty(item.key, item.color)
            })
            break;
        }
      }
    }
  }
}


const observer = new MutationObserver(() => {
  document.querySelectorAll('img:not([alt]), img[alt=""]').forEach(img => {
    img.setAttribute('alt', img.src?.split('/').pop());
  });
});

observer.observe(document.body, { childList: true, subtree: true });