define("@zestia/ember-simple-infinite-scroller/components/infinite-scroller", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/runloop", "@ember/object", "rsvp", "@ember/service", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _runloop, _object, _rsvp, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)( /*
                                                                                <div
                                                                                class={{
                                                                                  concat
                                                                                  "infinite-scroller"
                                                                                  (if this.isLoading " infinite-scroller--loading")
                                                                                  (if this.isScrollable " infinite-scroller--scrollable")
                                                                                }}
                                                                                ...attributes
                                                                                {{did-insert this.handleInsertElement}}
                                                                                {{will-destroy this.handleDestroyElement}}
                                                                              >
                                                                                {{
                                                                                  yield
                                                                                  (hash
                                                                                    error=this.error
                                                                                    isScrollable=this.isScrollable
                                                                                    isLoading=this.isLoading
                                                                                    loadMore=this.loadMore
                                                                                  )
                                                                                }}
                                                                              </div>
                                                                              */{
    "id": "z6kdPdJJ",
    "block": "[[[11,0],[16,0,[28,[37,0],[\"infinite-scroller\",[52,[30,0,[\"isLoading\"]],\" infinite-scroller--loading\"],[52,[30,0,[\"isScrollable\"]],\" infinite-scroller--scrollable\"]],null]],[17,1],[4,[38,2],[[30,0,[\"handleInsertElement\"]]],null],[4,[38,3],[[30,0,[\"handleDestroyElement\"]]],null],[12],[1,\"\\n  \"],[18,2,[[28,[37,5],null,[[\"error\",\"isScrollable\",\"isLoading\",\"loadMore\"],[[30,0,[\"error\"]],[30,0,[\"isScrollable\"]],[30,0,[\"isLoading\"]],[30,0,[\"loadMore\"]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"concat\",\"if\",\"did-insert\",\"will-destroy\",\"yield\",\"hash\"]]",
    "moduleName": "@zestia/ember-simple-infinite-scroller/components/infinite-scroller.hbs",
    "isStrictMode": false
  });
  let InfiniteScrollerComponent = (_dec = (0, _service.inject)('-infinite-scroller'), (_class = class InfiniteScrollerComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "_infiniteScroller", _descriptor, this);
      _initializerDefineProperty(this, "error", _descriptor2, this);
      _initializerDefineProperty(this, "isLoading", _descriptor3, this);
      _initializerDefineProperty(this, "isScrollable", _descriptor4, this);
      _defineProperty(this, "element", null);
    }
    get selector() {
      return this.args.selector || null;
    }
    get useDocument() {
      return this.args.useDocument || false;
    }
    get scrollDebounce() {
      return this.args.scrollDebounce || 100;
    }
    get leeway() {
      return parseInt(this.args.leeway || '0%', 10);
    }
    handleInsertElement(element) {
      this._registerElement(element);
      this._scheduleCheckScrollable();
      this._listen();
    }
    handleDestroyElement() {
      this._stopListening();
      this._deregisterElement();
    }
    loadMore() {
      this._loadMore();
    }
    _registerElement(element) {
      this.element = element;
    }
    _deregisterElement() {
      this.element = null;
    }
    _isScrollable() {
      let element = this._scroller();
      if (this.useDocument) {
        element = this._documentElement();
      }
      if (!element) {
        return;
      }
      return element.scrollHeight > element.clientHeight;
    }
    _scheduleCheckScrollable() {
      (0, _runloop.scheduleOnce)('afterRender', this, '_checkScrollable');
    }
    _checkScrollable() {
      this.isScrollable = this._isScrollable();
    }
    _listen() {
      this._scrollHandler = this._scroll.bind(this);
      this._listener().addEventListener('scroll', this._scrollHandler);
    }
    _stopListening() {
      this._listener().removeEventListener('scroll', this._scrollHandler);
      (0, _runloop.cancel)(this._scrollDebounceId);
    }
    _scroll(e) {
      this._scrollDebounceId = (0, _runloop.debounce)(this, '_debouncedScroll', e, this.scrollDebounce);
    }
    _debouncedScroll() {
      if (this._shouldLoadMore()) {
        this._loadMore();
      }
    }
    _log() {
      this._infiniteScroller.log(...arguments);
    }
    _document() {
      return this._infiniteScroller.document;
    }
    _documentElement() {
      return this._infiniteScroller.documentElement;
    }
    _listener() {
      if (this.useDocument) {
        return this._document();
      } else {
        return this._scroller();
      }
    }
    _scroller() {
      if (this.selector) {
        return this.element.querySelector(this.selector);
      } else {
        return this.element;
      }
    }
    _shouldLoadMore() {
      let state;
      if (this.useDocument) {
        state = this._detectBottomOfElementInDocument();
      } else {
        state = this._detectBottomOfElement();
      }
      state.shouldLoadMore = state.reachedBottom && !this.isLoading;
      this._log(state);
      return state.shouldLoadMore;
    }
    _detectBottomOfElementInDocument() {
      const scroller = this._scroller();
      const clientHeight = this._infiniteScroller.documentElement.clientHeight;
      const bottom = scroller.getBoundingClientRect().bottom;
      const leeway = this.leeway;
      const pixelsToBottom = bottom - clientHeight;
      const percentageToBottom = pixelsToBottom / bottom * 100;
      const reachedBottom = percentageToBottom <= leeway;
      return {
        clientHeight,
        bottom,
        leeway,
        pixelsToBottom,
        percentageToBottom,
        reachedBottom
      };
    }
    _detectBottomOfElement() {
      const scroller = this._scroller();
      const scrollHeight = scroller.scrollHeight;
      const scrollTop = scroller.scrollTop;
      const clientHeight = scroller.clientHeight;
      const bottom = scrollHeight - clientHeight;
      const leeway = this.leeway;
      const pixelsToBottom = bottom - scrollTop;
      const percentageToBottom = pixelsToBottom / bottom * 100;
      const reachedBottom = percentageToBottom <= leeway;
      return {
        scrollHeight,
        scrollTop,
        clientHeight,
        bottom,
        leeway,
        pixelsToBottom,
        percentageToBottom,
        reachedBottom
      };
    }
    _loadMore() {
      const action = this.args.onLoadMore;
      if (typeof action !== 'function') {
        return;
      }
      this.error = null;
      this.isLoading = true;
      (0, _rsvp.resolve)(action()).catch(this._loadError.bind(this)).finally(this._loadFinished.bind(this));
    }
    _loadError(error) {
      this.error = error;
    }
    _loadFinished() {
      this.isLoading = false;
      this._scheduleCheckScrollable();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_infiniteScroller", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isScrollable", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleInsertElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsertElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDestroyElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDestroyElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMore", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadMore"), _class.prototype)), _class));
  _exports.default = InfiniteScrollerComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InfiniteScrollerComponent);
});